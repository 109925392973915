module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-tinacms/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/opt/render/project/src/node_modules/gatsby-tinacms-git","id":"ca65af08-4c10-5e6f-8b14-831709d38ab4","name":"gatsby-tinacms-git","version":"0.4.7","pluginOptions":{"plugins":[]},"nodeAPIs":["onCreateDevServer"],"browserAPIs":["onClientEntry"],"ssrAPIs":[]},{"resolve":"/opt/render/project/src/node_modules/gatsby-tinacms-remark","id":"4e049a88-2f70-5135-883f-87a76d01585d","name":"gatsby-tinacms-remark","version":"0.7.13","pluginOptions":{"plugins":[]},"nodeAPIs":["setFieldsOnGraphQLNodeType"],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/opt/render/project/src/node_modules/gatsby-plugin-svgr","id":"69769d9e-bbde-5aca-a823-ca7998dd4ec0","name":"gatsby-plugin-svgr","version":"2.0.2","pluginOptions":{"plugins":[]},"nodeAPIs":["onCreateWebpackConfig"],"browserAPIs":[],"ssrAPIs":[]}],"sidebar":{"hidden":true,"position":"displace","theme":{"color":{"primary":{"light":"#F1BF11","medium":"#F1BF11","dark":"#F1BF11"}}}}},
    },{
      plugin: require('../node_modules/gatsby-tinacms-git/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/render/project/src/src/components/siteLayout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Liz Slocum Jensen","short_name":"Liz Slocum Jensen","start_url":"/","background_color":"#F1BF11","theme_color":"#F1BF11","display":"minimal-ui","icon":"content/images/icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":880,"withWebp":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Lato:400,700"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
